import Service from "./Service";

const basePath = '/api/agrupacion';

export default {
    search(query){
        const url = `${basePath}/search?s=${query}`;
        return Service.get(url);
    },
    show(id){
        const url = `${basePath}/show/${id}`;
        return Service.get(url);
    }
}
